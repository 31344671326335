<p-table
  [columns]="columnsFilter ? selectedColumns : columns"
  [loading]="loading"
  [paginator]="
    (stateKey && !tableRef.stateRestored) || (lazy && rows > 0) || totalRecords > tableRows
  "
  [value]="items"
  [autoLayout]="true"
  [lazy]="lazy"
  [totalRecords]="totalRecords"
  [rows]="tableRows"
  [rowHover]="true"
  [rowsPerPageOptions]="rowsPerPageOptions"
  [class.bo-table--collapse]="collapsibleRowTemplate || collapsible"
  [(selection)]="selection"
  [selectionMode]="selectionMode"
  (onRowSelect)="onRowSelect($event)"
  (onRowUnselect)="onRowSelect($event, false)"
  (onLazyLoad)="onLazyLoadFn($event)"
  (keyup.arrowUp)="selectItem(-1)"
  (keyup.arrowDown)="selectItem(1)"
  [reorderableColumns]="true"
  (onSort)="showSaveTableTooltip(true)"
  (onColReorder)="showSaveTableTooltip(); this.columnsFilter && onColumnsReorder($event)"
  (onPage)="onPaging($event)"
  stateStorage="local"
  compareSelectionBy="equals"
  [metaKeySelection]="false"
  [rowExpandMode]="rowExpandMode"
  csvSeparator=";"
  class="bo-table"
  [dataKey]="indexColumn"
  tabindex="-1"
  #tableRef
>
  <ng-template pTemplate="caption">
    <div *ngIf="showResultCount" class="bo-table__caption">
      <span class="bo-table__caption-results">{{
        'MTR_COMMON_N_RESULTS' | translate: { results: totalRecords || 0 }
      }}</span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr class="bo-table__header" [mcuiContextMenuTriggerFor]="m.menu" [mcuiContextMenuDisabled]="!columnsFilter">
      <th *ngIf="collapsibleRowTemplate || collapsible" class="bo-table__header-collapsible"></th>
      <th *ngIf="selectionMode === SelectionMode.Multiple || multiCheckSelection">
        <div (click)="toggleAllRowsSelection($event)" class="bo-table__header-checkbox">
          <p-tableHeaderCheckbox [disabled]="disabled" #headerCheckBox></p-tableHeaderCheckbox>
        </div>
      </th>
      <th
        class="bo-table__header-title"
        *ngFor="let col of columns"
        [ngClass]="col.extraClasses"
        [class.text-center]="
          ColumnDataType.Icon === col.type || col.extraClasses?.includes('text-center')
        "
        [pSortableColumn]="col.sortField"
        [pSortableColumnDisabled]="!col.sort || disabled"
        pReorderableColumn
      >
        {{ col.header | translate }}
        <p-sortIcon *ngIf="col.sort" [field]="col.sortField"></p-sortIcon>
      </th>
    </tr>
    <mcui-menu [items]="menuColumns" [menuItemTpl]="menuItemTpl" #m />
    <ng-template #menuItemTpl let-menuItem="menuItem">
      <mcui-checkbox
        [disable]="menuItem.disabled"
        [value]="menuItem.id"
        [label]="menuItem.label | translate"
        [(ngModel)]="selectedColumnsToFilter"
        [ngModelOptions]="{standalone:true}"
        (checkedChange)="onMenuCheckedChange($any($event))"
      />
    </ng-template>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-item
    let-columns="columns"
    let-expanded="expanded"
    let-rowIndex="rowIndex"
  >
    <ng-container *ngIf="SELECTABLE_SELECTION_MODES.includes(selectionMode)">
      <tr
        *ngIf="collapsibleRowTemplate || collapsible; else noCollapsibleRow"
        class="bo-table__body-row"
        [ngClass]="rowSettings?.classFn && rowSettings?.classFn(item)"
        [class.is-disabled]="
          (rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled
        "
        [attr.data-qa-id]="item?.qaId"
      >
        <ng-container *ngTemplateOutlet="bodyRow"></ng-container>
      </tr>
      <ng-template #noCollapsibleRow>
        <tr
          class="bo-table__body-row"
          [ngClass]="rowSettings?.classFn && rowSettings?.classFn(item)"
          [class.p-selectable-row]="false"
          [class.is-disabled]="
            (rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled
          "
          [pSelectableRowDisabled]="
            (rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled
          "
          [pSelectableRow]="item"
          [attr.data-qa-id]="item?.qaId"
        >
          <ng-container *ngTemplateOutlet="bodyRow"></ng-container>
        </tr>
      </ng-template>
      <ng-template #bodyRow>
        <td *ngIf="selectionMode === SelectionMode.Multiple" class="bo-table__cell--min-width">
          <app-table-checkbox
            [value]="item"
            [disabled]="(rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled"
          ></app-table-checkbox>
        </td>
        <ng-container *ngTemplateOutlet="tableCollapseCell"></ng-container>
        <ng-container *ngIf="fullCustomRowTemplate">
          <td
            class="bo-table__body-cell"
            [attr.colspan]="columns.length + (selectionMode === SelectionMode.Multiple ? 2 : 1)"
          >
            <ng-container
              *ngTemplateOutlet="fullCustomRowTemplate; context: { $implicit: item }"
            ></ng-container>
          </td>
        </ng-container>
        <ng-container *ngIf="!fullCustomRowTemplate">
          <ng-container
            *ngTemplateOutlet="
              tableRow;
              context: { item: item, columns: columns, rowIndex: rowIndex }
            "
          ></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>
    <tr
      *ngIf="selectionMode === SelectionMode.None"
      class="bo-table__body-row"
      [ngClass]="rowSettings?.classFn && rowSettings?.classFn(item)"
      [class.is-disabled]="(rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled"
      [attr.data-qa-id]="item?.qaId"
    >
      <td *ngIf="multiCheckSelection" class="bo-table__cell--min-width">
        <app-table-checkbox
          [value]="item"
          [disabled]="(rowSettings?.isDisableFn && rowSettings?.isDisableFn(item)) || disabled"
        ></app-table-checkbox>
      </td>
      <ng-container *ngTemplateOutlet="tableCollapseCell"></ng-container>
      <ng-container *ngIf="fullCustomRowTemplate">
        <td
          class="bo-table__body-cell"
          [attr.colspan]="columns.length + (selectionMode === SelectionMode.Multiple ? 2 : 1)"
        >
          <ng-container
            *ngTemplateOutlet="fullCustomRowTemplate; context: { $implicit: item }"
          ></ng-container>
        </td>
      </ng-container>
      <ng-container *ngIf="!fullCustomRowTemplate">
        <ng-container
          *ngTemplateOutlet="
            tableRow;
            context: { item: item, columns: columns, rowIndex: rowIndex }
          "
        ></ng-container>
      </ng-container>
    </tr>
    <tr
      *ngIf="collapsibleRowTemplate"
      class="bo-table__collapse-row"
      [class.bo-table__collapse-row--collapsed]="!expanded"
      [attr.data-qa-id]="item?.qaId"
    >
      <td [attr.colspan]="columns.length + (selectionMode === SelectionMode.Multiple ? 2 : 1)">
        <div
          class="bo-table__collapse-body-wrapper"
          [style.max-height.px]="expanded ? cell?.scrollHeight : 0"
          #cell
        >
          <div class="bo-table__collapse-body">
            <ng-container
              *ngTemplateOutlet="collapsibleRowTemplate; context: { $implicit: item }"
            ></ng-container>
          </div>
        </div>
      </td>
    </tr>
    <ng-template #tableCollapseCell>
      <td
        *ngIf="
          collapsibleRowTemplate || (collapsible && (item[collapsibleItemsKey] || []).length);
          else emptyCell
        "
        (click)="$event.stopPropagation()"
        [pRowToggler]="item"
        [class.bo-table__collapse-cell--collapsed]="!expanded"
        class="bo-table__collapse-cell"
      >
        <i class="bo-table__collapse-icon pi pi-chevron-right" aria-hidden="true"></i>
      </td>
      <ng-template #emptyCell>
        <td *ngIf="collapsible" class="bo-table__body-cell"></td>
      </ng-template>
    </ng-template>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-item let-columns="columns" let-rowIndex="rowIndex">
    <tr
      *ngFor="let collapsibleItem of item[collapsibleItemsKey] || []"
      class="bo-table__body-row bo-table__collapsible-row"
      [class.p-highlight]="selectionChangeSubItemValue === collapsibleItem"
      [class.p-selectable-row]="SELECTABLE_SELECTION_MODES.includes(selectionMode)"
      (click)="selectionChangeSubItem$.next(collapsibleItem)"
      [attr.data-qa-id]="collapsibleItem?.qaId"
    >
      <td class="bo-table__body-cell"></td>
      <ng-container
        *ngTemplateOutlet="
          tableRow;
          context: { item: collapsibleItem, columns: columns, rowIndex: rowIndex }
        "
      ></ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorleft" let-columns>
    <div class="bo-button-group bo-button-group--separated">
      <app-button
        *ngIf="stateKey"
        [text]="'MTR_COMMON_TABLE'"
        (onClick)="saveState()"
        tooltipText="MTR_DESCRIPTION_TABLE_SAVING"
        icon="save"
        [extraClasses]="'bo-button--secondary bo-button--icon-left'"
        #tooltipButton
      >
      </app-button>
      <app-button
        [text]="'MTR_COMMON_EXPORT_CSV'"
        icon="download"
        extraClasses="bo-button--secondary bo-button--icon-left"
        (onClick)="exportCSV()"
      >
      </app-button>
    </div>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr class="bo-table__body-row">
      <td
        class="bo-table__body-cell"
        [attr.colspan]="columns.length + (collapsibleRowTemplate || collapsible ? 1 : 0)"
      >
        {{ (items ? 'MTR_COMMON_NO_RESULTS' : '') | translate }}
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #iconWrapper let-item="item" let-col="col">
  <ng-container
    *ngTemplateOutlet="
      icon;
      context: {
        iconInfo: {
          icon:
            col.icon ||
            (col.iconFn && col.iconFn(item, col)) ||
            (item[col.field] ? IconType.Success : null),
          tooltipText: item[col.tooltipField],
          extraClasses: col.iconExtraClassesFn && col.iconExtraClassesFn(item, col),
        },
      }
    "
  ></ng-container>
</ng-template>

<ng-template #icon let-iconInfo="iconInfo">
  <app-icon
    [icon]="iconInfo.icon"
    [extraClasses]="iconInfo.extraClasses"
    [tooltipText]="iconInfo.tooltipText"
  ></app-icon>
</ng-template>

<ng-template #tableRow let-item="item" let-columns="columns" let-rowIndex="rowIndex">
  <td
    class="bo-table__body-cell"
    *ngFor="let col of columns"
    [ngClass]="col.extraClasses"
    [class.bo-table__cell--min-width]="col.type === ColumnDataType.ContextMenu"
    [class.text-center]="
      ColumnDataType.Icon === col.type || col.extraClasses?.includes('text-center')
    "
    [ngSwitch]="col.type"
  >
    <ng-template [ngSwitchCase]="ColumnDataType.Date">{{
      FromUTC(item[col.field])
        | date: DATE_TYPE_LOCALE[language$ | async][col.datePattern || DateType.Date]
    }}</ng-template>
    <ng-template [ngSwitchCase]="ColumnDataType.Icon">
      <ng-container
        *ngTemplateOutlet="iconWrapper; context: { item: item, col: col }"
      ></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="ColumnDataType.TextIcon">
      <div class="bo-table__cell-text-icon-wrapper">
        <span *ngFor="let textIcon of col.textIcons" class="bo-table__cell-text-icon">
          <ng-container
            *ngTemplateOutlet="iconWrapper; context: { item: item, col: textIcon }"
          ></ng-container>
          {{ (item[textIcon.field] !== null ? item[textIcon.field] : '')?.toString() | translate }}
        </span>
      </div>
    </ng-template>
    <ng-template [ngSwitchCase]="ColumnDataType.Toggle">
      {{ (item[col.field] ? col.onLabel : col.offLabel) | translate }}
    </ng-template>
    <ng-template [ngSwitchCase]="ColumnDataType.Custom">
      <ng-container
        *ngTemplateOutlet="
          customRowTemplate;
          context: { $implicit: { item: item, col: col, rowIndex: rowIndex } }
        "
      ></ng-container>
    </ng-template>
    <ng-template [ngSwitchCase]="ColumnDataType.ContextMenu">
      <app-icon
        class="bo-table__context-menu-icon"
        [class.is-open]="contextMenu.isVisible"
        icon="ellipsis-v"
        extraClasses="u-font-size--l"
        [isNativeIcon]="true"
        (click)="!disabled && contextMenu.toggle($event)"
      ></app-icon>
      <app-menu [items]="col.options" [data]="item" #contextMenu></app-menu>
    </ng-template>
    <ng-template ngSwitchDefault>
      <ng-template [ngIf]="item[col.field] !== null">{{
        item[col.field]?.toString() | translate
      }}</ng-template>
    </ng-template>
  </td>
</ng-template>
