import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'

import { BehaviorSubject, of } from 'rxjs'
import { debounceTime, map, switchMap, take } from 'rxjs/operators'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

import { SelectButton } from 'primeng/selectbutton'
import { SelectComponent, TranslatorService } from '@mediacoach-ui-library/global'

import { environment } from '@env'
import { cloneObject, isIncluded, uniqueId } from '@core/utils/main'
import { UsersBOService } from '@features/bo-users/usersBO.service'
import { UserProfileType } from '@features/user-customers/components/users/users.enums'
import { UserCustomerViewService } from '@features/user-customers/user-customer-view.service'

import { FORM_INPUTS, PASSWORD_EXPIRATION_OPTIONS, VALIDATORS } from './general-form.constants'
import { FormInputSetting, InputSetting } from './general-form.models'
import { InputType, TemplateType } from './general-form.enums'
import { User } from '@features/user-customers/components/users/users.models'
import { UserBO } from '@features/bo-users/usersBO.models'
import { UserProfile } from '@core/authentication/auth/models/auth.models'
import { Store } from '@ngrx/store'
import { isAdmin } from '@core/state/selectors/profile.selectors'

@UntilDestroy()
@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
})
export class GeneralFormComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  private _inputs = {
    disabled: [],
    multiSwitch: [],
  }
  private _selectButtons = {}

  @Input() userData: UserProfile | UserBO | User
  @Input() isEdit = false
  @Input() type = TemplateType.UserProfile
  @Output() onChange = new EventEmitter<UserProfile | User>()
  @Output() revokePassword = new EventEmitter<string[]>()
  @Output() resendWelcomeEmail = new EventEmitter<string[]>()

  @ViewChildren('selectButtons') selectButtonComponents: QueryList<SelectButton>
  @ViewChildren('selectButtons', { read: ElementRef }) selectButtonElemRefs: QueryList<ElementRef>
  @ViewChildren(SelectComponent) dropdowns: QueryList<SelectComponent<any>>

  VALIDATORS = VALIDATORS
  InputType = InputType
  TemplateType = TemplateType
  isIncluded = isIncluded

  userForm: UntypedFormGroup
  formInputs: FormInputSetting
  uniqueIdPrefix = uniqueId()
  language$: BehaviorSubject<string>

  readonly isAdmin$ = this._store.select(isAdmin)
  readonly profileType = UserProfileType
  readonly defaultAvatarColor = '#a1a1a1'

  constructor(
    private fb: UntypedFormBuilder,
    private readonly _store: Store<unknown>,
    // private authService: AuthService,
    private userBOService: UsersBOService,
    private translatorService: TranslatorService,
    private userCustomerViewService: UserCustomerViewService,
  ) {
    this.language$ = this.translatorService.onLangChange
  }

  // private _initIsAdminTrigger() {
  //   if (this.formInputs['profileIds']) {
  //     this._store.pipe(select(isAdmin), untilDestroyed(this)).subscribe((boolean) => this.isAdmin = userProfile.roles.includes(RoleType.Admin))
  //   }
  // }

  private _createFormInputs() {
    this.formInputs = Object.entries(cloneObject(FORM_INPUTS)).reduce(
      (obj, [key, inputSetting]) => ({
        ...obj,
        ...((inputSetting as InputSetting).templates.includes(this.type)
          ? { [key]: inputSetting }
          : {}),
      }),
      {},
    )
    // this._initIsAdminTrigger()
  }

  private _getInputOptions() {
    if (this.formInputs['customerId']) {
      this.formInputs['customerId'].options = this.userCustomerViewService.getCustomerOptions$()
    }
    if (this.formInputs['profileIds']) {
      this.formInputs['profileIds'].options = this.userCustomerViewService
        .getUserProfileTypeOptions$()
        .pipe(
          switchMap((options) =>
            this.isAdmin$.pipe(
              take(1),
              map((isAdmin) => ({ options, isAdmin })),
            ),
          ),
          map(({ options, isAdmin }) =>
            options.map((option) => ({
              ...option,
              disabled: this.shouldDisableOption(option.value, isAdmin),
            })),
          ),
        )
    }
    if (this.formInputs['roleIds']) {
      this.formInputs['roleIds'].options = this.userBOService.getRoleOptions$()
    }
    if (this.formInputs['language']) {
      this.formInputs['language'].options = this.userCustomerViewService.getLanguageOptions$()
    }
    if (this.formInputs['positionId']) {
      this.formInputs['positionId'].options = this.userCustomerViewService.getPositionOptions$()
    }
    if (this.formInputs['categoryId']) {
      this.formInputs['categoryId'].options =
        this.userCustomerViewService.getCategoryGroupOptions$()
    }
    if (this.formInputs['passwordExpiration']) {
      this.formInputs['passwordExpiration'].options = of(PASSWORD_EXPIRATION_OPTIONS)
    }
  }

  private _buildForm() {
    const disableInputKeys = []
    const multiSwitchInputKeys = []
    const controlsConfig = Object.entries(this.formInputs).reduce((obj, [key, inputInfo]) => {
      if (inputInfo.disabled) {
        disableInputKeys.push(key)
      }
      if (inputInfo.type === InputType.SwitchMultiple) {
        multiSwitchInputKeys.push(key)
      }
      return {
        ...obj,
        [key]: [
          { value: inputInfo.value || '', disabled: !!inputInfo.disabled },
          inputInfo.validators || [],
        ],
      }
    }, {})
    this.userForm = this.fb.group(controlsConfig)
    this._inputs.disabled = disableInputKeys.map((inputKey) => this.userForm.get(inputKey))
    this._inputs.multiSwitch = multiSwitchInputKeys
  }

  private _updateFormFields(params?) {
    if (this.userData && JSON.stringify(this.userData) !== '{}') {
      if (this._inputs.multiSwitch.length > 0) {
        this._inputs.multiSwitch
          .filter((inputKey) => !!this._selectButtons[inputKey])
          .forEach((inputKey) => {
            this._selectButtons[inputKey].options.forEach(
              (option) => (option.checked = this.userData[inputKey].includes(option.value)),
            )
          })
      }
    }

    this.userForm.reset(this.userData || {}, params)
  }

  private _checkFormEnableness() {
    this.userForm[this.isEdit ? 'enable' : 'disable']({ emitEvent: false })
    this._inputs.disabled.forEach((input) => input.disable({ emitEvent: false }))
    if (this.isEdit) {
      Object.keys(this.userForm.controls).forEach((key) =>
        this.userForm.controls[key].markAsTouched(),
      )
    }
  }

  private _formSubscription() {
    this.userForm.valueChanges
      .pipe(debounceTime(environment.DEBOUNCE_TIME.LONG), untilDestroyed(this))
      .subscribe((userForm) => {
        this.onChange.emit(this.userForm.valid ? userForm : null)
      })
  }

  ngOnInit() {
    this._createFormInputs()
    this._getInputOptions()
    this._buildForm()
    this._updateFormFields()
    this._checkFormEnableness()
    this._formSubscription()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userData'] && !changes['userData'].isFirstChange()) {
      this._updateFormFields({ emitEvent: false })
    }
    if (changes['isEdit'] && !changes['isEdit'].isFirstChange()) {
      this._checkFormEnableness()
    }
  }

  ngAfterViewInit() {
    if (this.selectButtonComponents) {
      this.selectButtonElemRefs.forEach((item, index) => {
        this._selectButtons[item.nativeElement.id] = this.selectButtonComponents.find(
          (a, i) => i === index,
        )
      })
    }
  }

  ngOnDestroy() {
    // This is intentional
  }

  shouldDisableOption(value, isAdmin) {
    return value === UserProfileType.BackOfficeUserProfile && !isAdmin
  }

  resetForm() {
    this._updateFormFields({ emitEvent: false })
  }

  forceDetectChanges() {
    // nasty trick to overcome the loss of change detection chain
    this.dropdowns.forEach((d) => d.detectChanges())
  }

  onRevokePassword() {
    this.revokePassword.emit([this.userData.id])
  }

  onResendWelcomeEmail() {
    this.resendWelcomeEmail.emit([this.userData.id])
  }

  onAvatarChange(avatar: any) {
    if (avatar.result) {
      this.userForm.get('photoPath').patchValue(avatar.result)
    }
  }
}
