import { EnvType, firstLetterToLowerCase, Locale } from '@mediacoach-ui-library/global'

import { AuthPage } from '@core/authentication/auth/enums/auth.enums'

import { MAIN_APP_API, MAIN_APP_NAME, VARIABLES_BY_ENVIRONMENT } from './environment.constants'
import packageJson from '../../package.json'

const concatId = (id: string) => (id ? `/${id}` : '')

const getEnvironmentInfo = (env: EnvType) => {
  const { APP_DOMAIN, IDENTITY_SERVER, API_DOMAIN, API_PROXY_BKND_DOMAIN } = getDomainInfo(env)
  const API_BACKOFFICE_URL = `${API_DOMAIN}/backofficebackend/api/`
  const STATICS_URL_ROOT = getUrlByEnv('https://festatics.mediacoach.es/', env)
  const STATICS_FULL_URL = (path: string) => STATICS_URL_ROOT + firstLetterToLowerCase(path)

  const ORIGIN_DOMAIN = window.location.origin

  const CATEGORY_BY_ID = (id?: string) => `${API_BACKOFFICE_URL}categories${concatId(id || '')}`

  return {
    production: false,
    serverMocks: false,

    API_KEY_SECURE: 'Bearer',
    GENERAL_DEBOUNCE_TIME: 200,

    DEBOUNCE_TIME: {
      FOR_CRASHES: 10,
      INPUT_CHANGES: 100,
      LONG: 200,
    },

    STATICS_URL_ROOT,

    STATICS_URL_ASSETS: 'assets/',

    LEGAL_NOTICE_URL: (lang: string) =>
      STATICS_FULL_URL(`Licenser/LicenseAgreement/License-Agreement_${lang}.html`),

    STATICS_FULL_URL,

    PROFILE_TYPE_BLACKLIST: [],

    CURRENT_DEACTIVATED_PAGES: {
      [AuthPage.Alerts]: true,
      [AuthPage.Dashboard]: true,
      [AuthPage.Monitoring]: true,
      [AuthPage.GrafanaWidgets]: true,
      [AuthPage.MetricsManager]: true,
    },

    // FRONT URLS
    REDIRECT_LOGIN_URL: '/login',
    REDIRECT_LOGGED_URL: '/data-publishing',
    NOT_PERMITTED_URL: '/not-permitted',

    I18N_LANGS: {
      [Locale.Es]: {
        locale: 'es-ES',
        i18nLabel: 'MTR_COMMON_LANG_ES',
      },
      [Locale.En]: {
        locale: 'en-GB',
        i18nLabel: 'MTR_COMMON_LANG_EN',
      },
    },

    API: {
      MAIN_PATH: API_BACKOFFICE_URL,
      TENANTS: `${API_BACKOFFICE_URL}tenants`,
      USER_PROFILE: `${API_BACKOFFICE_URL}users/profile`,
      USERS: `${API_BACKOFFICE_URL}users`,
      SEARCH_USERS: `${API_BACKOFFICE_URL}users/search`,
      USER_POSITIONS: `${API_BACKOFFICE_URL}users/positions`,
      USER_PROFILE_TYPES: `${API_BACKOFFICE_URL}userprofile/types`,
      USERS_BO: `${API_BACKOFFICE_URL}backoffice/users`,
      SEARCH_USERS_BO: `${API_BACKOFFICE_URL}backoffice/users/search`,
      USER_BO_ROLES: `${API_BACKOFFICE_URL}backoffice/roles`,
      SEARCH_CUSTOMERS: `${API_BACKOFFICE_URL}customers/search`,
      USERS_ACTIVATIONS: `${API_BACKOFFICE_URL}users/activations`,
      USER_PASSWORD_EXPIRATION: `${API_BACKOFFICE_URL}users/forcePasswordExpiration`,
      USER_RESEND_WELCOME_EMAIL: `${API_BACKOFFICE_URL}users/resentWelcomeEmail`,
      USERS_LICENSES: `${API_BACKOFFICE_URL}users/licenses`,
      MATCHES: `${API_BACKOFFICE_URL}match`,
      SEARCH_MATCHES: `${API_BACKOFFICE_URL}match/search`,
      UPDATE_MATCH: (matchId: string) => `${API_BACKOFFICE_URL}match/${matchId}`,
      MATCH_QUALIFIERS: `${API_BACKOFFICE_URL}match/qualifiers`,
      PRIVATE_MEDIA: `${API_BACKOFFICE_URL}PrivateMedia`,
      SEARCH_SALES: `${API_BACKOFFICE_URL}sales/search`,
      CRUD_SALE: `${API_BACKOFFICE_URL}sales/sale`,
      CRUD_MARKETABLE_PACKAGE: `${API_BACKOFFICE_URL}marketables/package`,
      CRUD_CUSTOMER: `${API_BACKOFFICE_URL}customers`,
      SEARCH_MARKETABLE_PACKAGES: `${API_BACKOFFICE_URL}marketables/search`,

      SEASONS: `${API_BACKOFFICE_URL}seasons`,
      COMPETITIONS: `${API_BACKOFFICE_URL}competitions`,
      MATCH_DAYS: `${API_BACKOFFICE_URL}matchdays`,
      SEARCH_STADIUMS: `${API_BACKOFFICE_URL}venue/search`,
      CRUD_STADIUM: `${API_BACKOFFICE_URL}Venue`,
      SEARCH_LICENSES: `${API_BACKOFFICE_URL}licenses/search`,
      SEARCH_STADIUM_MATCHES: `${API_BACKOFFICE_URL}Venue/matches/search`,
      LICENSE_PRODUCTS: `${API_BACKOFFICE_URL}licenses/products`,
      LICENSE_STATES: `${API_BACKOFFICE_URL}licenses/states`,
      LICENSE_CUSTOMERS: `${API_BACKOFFICE_URL}licenses/customers`,
      CRUD_LICENSE: `${API_BACKOFFICE_URL}licenses`,
      LICENSE_DONGLE: `${API_BACKOFFICE_URL}licenses/dongles`,
      LICENSE_RENEW: `${API_BACKOFFICE_URL}licenses/renew-generate-v2c`,
      LICENSE_REJECT: `${API_BACKOFFICE_URL}licenses/reject`,
      LICENSE_SYNC: `${API_BACKOFFICE_URL}licenses/sync/all`,
      LICENSE_VALIDATE_C2V: `${API_BACKOFFICE_URL}licenses/dongles/c2v`,
      INCIDENCE_TYPES: `${API_BACKOFFICE_URL}incidences/incidencetypes`,
      INCIDENCE_RESOLUTION_TYPES: `${API_BACKOFFICE_URL}incidences/incidenceresolutiontypes`,
      INCIDENCE_CAMERA_TYPOLOGIES: `${API_BACKOFFICE_URL}incidences/cameratypologies`,
      INCIDENCE_APPLICATIONS: `${API_BACKOFFICE_URL}incidences/applications`,
      CRUD_INCIDENCE: `${API_BACKOFFICE_URL}incidences`,
      TRACKING_PROVIDERS: `${API_BACKOFFICE_URL}match-qualifiers/trackingproviders`,
      SEARCH_TEAMS: `${API_BACKOFFICE_URL}teams/search`,
      SEARCH_PLAYERS: `${API_BACKOFFICE_URL}players/search`,
      UPDATE_PLAYER: `${API_BACKOFFICE_URL}players`,
      UPLOAD_PLAYER_FILES: (seasonId) =>
        `${API_BACKOFFICE_URL}teams/UploadExcel?seasonId=${seasonId}`,
      GET_PLAYER_POSITIONS: `${API_BACKOFFICE_URL}players/mcpositions`,

      TRANSLATIONS: (language: string) => `${API_BACKOFFICE_URL}translations?language=${language}`,

      GET_URL_DOWNLOAD: '',
      PERMISSION_CONFIG: `${API_BACKOFFICE_URL}permissions`,
      CATEGORY_BY_ID,
      SEARCH_CATEGORIES: `${CATEGORY_BY_ID()}/search`,
      SAVE_CATEGORY: CATEGORY_BY_ID(),
      CRUD_VISION: `${API_BACKOFFICE_URL}permissions/vision`,
    },

    METRICS_MANAGER_API: getProxyBackendApi(API_PROXY_BKND_DOMAIN),

    IDENTITY_SERVER: {
      API_LOGOUT: `${IDENTITY_SERVER}/connect/endsession`,
      AUTHORITY_URL: IDENTITY_SERVER,
      REDIRECT_URI: `${ORIGIN_DOMAIN}/auth`,
      REGISTER_ACCOUNT_URL: `${IDENTITY_SERVER}/Account/Register`,
      POST_LOGOUT_REDIRECT_URI: APP_DOMAIN,
      TAG_POST_LOGOUT_REDIRECT_URI: 'post_logout_redirect_uri',
      SILENT_REDIRECT_URI: `${ORIGIN_DOMAIN}/silent`,
      CLIENT_ID: '39ed393d-eedd-496c-ab82-668a2346836f',
      ACCESS_TOKEN_EXPIRING_NOTIFICATION_TIME: 4,
      LOAD_USER_INFO: true,
      RESPONSE_TYPE: 'code',
      SCOPE: 'openid profile backoffice backoffice-backend metmgr-backoffice',
    },
  }
}

const getDomainInfo = (env: EnvType) => ({
  APP_DOMAIN: getUrlByEnv(`https://${MAIN_APP_NAME}.mediacoach.es`, env),
  IDENTITY_SERVER: getUrlByEnv('https://id.mediacoach.es', env),
  API_DOMAIN: getUrlByEnv(`https://${MAIN_APP_API}.mediacoach.es`, env),
  API_PROXY_BKND_DOMAIN: getUrlByEnv('https://proxybknd.mediacoach.es', env),
})

const getUrlByEnv = (domain: string, env: EnvType) => {
  const chunks = domain.split('.')
  chunks[0] += env === EnvType.Pr ? '' : `-${env}`
  return chunks.join('.')
}
export const getEnvironment = (env: EnvType, debugEnv?: EnvType) => ({
  envType: debugEnv ? debugEnv : env,
  BUILD_VERSION: packageJson?.version,
  BUILD_NUM: '1383',
  BUILD_DATE: '02012025.0909',
  BUILD_BRANCH: 'develop',

  ...getEnvironmentInfo(env),

  ...VARIABLES_BY_ENVIRONMENT[env],
})

export const getProxyBackendApi = (appDomain: string) => {
  const apiPath = `${appDomain}/metricsmanager/api/v1/`

  return {
    MAIN_PATH: apiPath,
    AGGREGATIONS: `${apiPath}aggregations`,
    CATEGORIES: `${apiPath}metriccategories`,
    TYPES: `${apiPath}metrictypes`,
    METRICS: `${apiPath}metrics`,
    SEARCH_METRICS: `${apiPath}metrics/searchmetricnames`,
    NEW_SQL: `${apiPath}generatesqlnewmetric`,
    EXISTING_SQL: `${apiPath}generatesqlexistingmetric`,
    SEARCH_METRIC_AND_DAG_BY_NAME: `${apiPath}searchmetricanddagbyname`,
  }
}
