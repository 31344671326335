<div class="bo_vision-form-btn">
  <app-button
    text="MTR_COMMON_NEW_ENTITY_VISION"
    [disabled]="formMode !== FormMode.Read"
    (onClick)="newEntityVision()"
  ></app-button>
</div>
<app-table
  [tableData]="tableConfigData"
  [columns]="columnSettings"
  [rows]="0"
  [multiCheckSelection]="true"
  [selection]="selectedData"
  (selectionChange)="selectionChange($event)"
  [showResultCount]="false"
  [columnsFilter]="false"
  [disabled]="disabled"
  [selectionMode]="SelectionMode.None"
>
</app-table>
