import {
  Input,
  OnInit,
  Output,
  ViewChild,
  Component,
  OnChanges,
  OnDestroy,
  QueryList,
  EventEmitter,
  SimpleChanges,
  AfterViewInit,
  ContentChildren,
  AfterContentInit,
  ChangeDetectorRef,
} from '@angular/core'

import { debounceTime, delay, filter, tap } from 'rxjs/operators'
import { BehaviorSubject, iif, isObservable, of, Subject, Subscription } from 'rxjs'

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  Table,
  TableColumnReorderEvent,
  TableHeaderCheckbox,
  TableRowSelectEvent,
} from 'primeng/table'
import { TranslatorService } from '@mediacoach-ui-library/global'

import { environment } from '@env'
import { fromUTC } from '@core/utils/main'
import { BoTemplateDirective } from '@shared/directives'
import { Paging } from '@core/services/paging/paging.models'
import { Sorting } from '@core/services/sorting/sorting.models'
import { PagingService } from '@core/services/paging/paging.service'
import { SortingService } from '@core/services/sorting/sorting.service'
import { ButtonComponent } from '@shared/components/button/button.component'

import { DATE_TYPE_LOCALE } from './table.constants'
import { LazyParams } from './table.models'
import { ColumnType, DateType, IconType, SelectionMode } from './table.enums'
import { MenuItem } from '@mediacoach/ui'

const TOOLTIP_LIFE = 3000
const STATE_KEY_PREFIX = 'bo-table__'
const TOOLTIP_STATE_KEY = 'bo-table--should-show-tooltip-auto'
const SELECTABLE_SELECTION_MODES = [SelectionMode.Single, SelectionMode.Multiple]

@UntilDestroy()
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, AfterContentInit
{
  private _isExporting = false
  private _nextSelection: any
  private _filtering: any
  private _isTooltipActivateAltered = false
  private _shouldShowTooltip = false
  private _headerCheckBox!: TableHeaderCheckbox
  private _lazyLoadDebouncer = new Subject()
  private _prevTableDataChanges$!: Subscription
  private _menuInteraction = false

  selectionChangeSubItemValue = null
  selectionChangeSubItem$ = new Subject()

  get headerCheckBox(): TableHeaderCheckbox {
    return this._headerCheckBox
  }
  @ViewChild('headerCheckBox') set headerCheckBox(_headerCheckBox: TableHeaderCheckbox) {
    if (_headerCheckBox && this._headerCheckBox !== _headerCheckBox) {
      const isDisableFn = this._isDisableFn
      _headerCheckBox.updateCheckedState = function () {
        const selectableRowIds = (this.dt.filteredValue || this.dt.value || [])
          .filter((row: any) => !isDisableFn(row))
          .map((row: any) => row[this.dt.dataKey])
        const selectionIds = (this.dt.selection || [])
          .filter((row: any) => selectableRowIds.includes(row[this.dt.dataKey]))
          .map((row: any) => row[this.dt.dataKey])

        this.checked =
          selectableRowIds.length > 0 &&
          selectableRowIds.every((rowId: any) => selectionIds.includes(rowId))

        return this.checked
      }
    }
    this._headerCheckBox = _headerCheckBox
  }

  @ViewChild('tooltipButton') tooltipButtonRef!: ButtonComponent
  @ViewChild('tableRef', { static: true }) tableRef!: Table
  @ContentChildren(BoTemplateDirective) templates!: QueryList<BoTemplateDirective>
  @Input() filters!: Subject<any> | BehaviorSubject<any>
  @Input() stateKey: any
  @Input() tableData: any
  @Input() selection: any
  @Input() rowExpandMode: any = 'single'
  @Input() rowHover = true
  @Input() selectionMode: any = SelectionMode.Single
  @Input() columns: any
  @Input() loading = true
  @Input() rowSettings: any
  @Input() indexColumn = 'id'
  @Input() rows = 10
  @Input() amountStep: number | number[] = 10
  @Input() showResultCount = true
  @Input() columnsFilter = true
  @Input() disableLoading = false
  @Input() collapsible = false
  @Input() multiCheckSelection = false
  @Input() disabled = false
  @Input() collapsibleItemsKey = 'subItems'
  @Output() selectionChange = new EventEmitter()
  @Output() selectionChangeSubItem = this.selectionChangeSubItem$.pipe(
    filter((value) => {
      const shouldChange = this.selectionChangeSubItemValue !== value
      if (!shouldChange) {
        this.selectionChangeSubItem$.next(null)
      }
      return shouldChange
    }),
    tap((value) => (this.selectionChangeSubItemValue = value as any)),
  )
  @Output() onLazyLoad = new EventEmitter<LazyParams>()

  items: any
  lazy = false
  tableRows: any
  totalRecords: any
  rowsPerPageOptions = []
  language$: BehaviorSubject<string>
  selectedColumnsToFilter: any[] = []
  selectedColumns: any[] = []
  menuColumns: MenuItem[] = []

  SelectionMode = SelectionMode
  FromUTC = fromUTC
  DateType = DateType
  IconType = IconType
  ColumnDataType = ColumnType
  DATE_TYPE_LOCALE = DATE_TYPE_LOCALE
  SELECTABLE_SELECTION_MODES = SELECTABLE_SELECTION_MODES

  customRowTemplate: any
  fullCustomRowTemplate: any
  collapsibleRowTemplate: any
  contextMenuTemplate: any

  constructor(
    private pagingService: PagingService,
    private sortingService: SortingService,
    private translatorService: TranslatorService,
    private ref: ChangeDetectorRef,
  ) {
    this.language$ = this.translatorService.onLangChange
  }

  private _initTriggers() {
    this.language$
      .pipe(
        filter((lang) => !!lang),
        untilDestroyed(this),
      )
      .subscribe(() => this._parseColumnSettings())
    this._lazyLoadDebouncer
      .pipe(debounceTime(environment.DEBOUNCE_TIME.FOR_CRASHES), untilDestroyed(this))
      .subscribe((data) => this._onLazyLoadFn(data))
  }

  private _isDisableFn = (...args: any) =>
    this.rowSettings && this.rowSettings.isDisableFn && this.rowSettings.isDisableFn(...args)

  private _startExportCSVLazy() {
    this._isExporting = true
    this.onLazyLoadFn({
      first: 0,
      rows: this.totalRecords,
      sortField: this.tableRef.sortField,
      sortOrder: this.tableRef.sortOrder,
    })
  }

  private _endExportCSVLazy(data: any) {
    this.tableRef.filteredValue = data
    this.tableRef.exportCSV()
    this.tableRef.filteredValue = null as any
    this._isExporting = false
  }

  private _parseColumnSettings() {
    const columnOrder = (this._getState() || {}).columnOrder
    const columnHeaders = this.columns
      .filter(({ translateHeader }: { translateHeader: any }) => translateHeader)
      .map(({ translateHeader }: { translateHeader: any }) => translateHeader)

    this.translatorService
      .get(columnHeaders.length > 0 ? columnHeaders : 'key')
      .subscribe((translatedHeaders) => {
        this.columns = this.columns.map((column: any) => ({
          ...column,
          header: translatedHeaders[column.translateHeader] || column.header,
          sortField: column.sortField || column.field,
        }))
      })

    if (this.columnsFilter && !columnOrder?.length) {
      this._configColumnsFilter()
    }
  }

  private _configColumnsFilter() {
    if (!this._menuInteraction) {
      this.selectedColumnsToFilter = this.columns?.map(({ field }) => field || '')
      this._setSelectedColumns()
    }
    this._setMenuColumns()
  }

  private _setSelectedColumns(filter = this.selectedColumnsToFilter) {
    this.selectedColumns = this.columns.filter((col: any) => filter.includes(col.field))
  }

  private _setMenuColumns(columns = this.columns) {
    this.menuColumns = columns?.map(({ field, header }, i: number) => ({
      id: field,
      label: header,
      disabled: i == 0,
    }))
  }

  private _restoreColumnsFilter() {
    if (this.columnsFilter) {
      this.selectedColumnsToFilter = (this._getState() || {}).columnOrder
      this._setSelectedColumns()
      this._reorderColumns(this.selectedColumnsToFilter)
    }
  }

  private _setTableData(tableData: any) {
    if (this._isExporting) {
      this._endExportCSVLazy(tableData.items)
    } else {
      this.tableData = tableData

      const isLazy = !Array.isArray(tableData)
      this.lazy = isLazy
      this.items = isLazy ? tableData?.items : tableData
      this.checkSelection()
      this._checkPagination(tableData?.paging)
      this._checkSorting(tableData?.sorting)
      this._checkTooltip()
      this.ref.detectChanges()
    }
  }

  private checkSelection() {
    if (this._nextSelection) {
      this.selection = this.items[this._nextSelection > 0 ? 0 : this.items?.length - 1]
      this.selectionChange.emit(this.selection)
      this.tableRef.el.nativeElement.focus()
      this._nextSelection = null
    }
  }

  private _checkPagination(paging: Paging) {
    this.tableRef.first = this.pagingService.getRangeStart(paging)
    this.tableRows = this.pagingService.getRange(paging) || this.rows || this.items?.length
    this._setTotalRecords(this.pagingService.getTotalRecords(paging) || this.items?.length)
  }

  private _checkSorting(sorting: Sorting) {
    const sortingColumns = this.sortingService.getSortingColumns(sorting)
    this.columns.forEach(
      (column: any) =>
        (column.sort = !this.lazy || (sortingColumns || []).includes(column.sortField)),
    )
    if (sorting) {
      const { column: field, direction: order } =
        this.sortingService.getSortedColumnByOrder(sorting)
      // PrimeNG Bug, next line will for rendering for highlight, sort icon...
      this.tableRef.tableService.onSort({ order, field })
    }
  }

  private _checkTooltip() {
    of(true)
      .pipe(delay(environment.DEBOUNCE_TIME.FOR_CRASHES))
      .subscribe(() => {
        this._alterTooltipActivateFn()
        if (this._shouldShowTooltip) {
          this.showSaveTableTooltip()
        }
      })
  }

  private _setTotalRecords(totalRecords: any) {
    this.totalRecords = totalRecords
    this._createRowsPerPageOptions()
  }

  private _createRowsPerPageOptions() {
    if (!this.rowsPerPageOptions[0]) {
      this.rowsPerPageOptions = Array.isArray(this.amountStep)
        ? this.amountStep
        : (Array(Math.ceil(this.totalRecords / this.amountStep))
            .fill(this.amountStep)
            .map((value, i) => value * (i + 1)) as any)
      if (this.totalRecords <= this.tableRows) {
        this.rowsPerPageOptions = this.rowsPerPageOptions.filter((v) => v <= this.tableRows)
      }
    }
  }

  private _onLazyLoadFn(data: any) {
    if (this.filters && this._filtering) {
      const { rows, first, sortField, sortOrder } = data || this.tableRef
      this.onLazyLoad.next({
        paging: this.pagingService.getParams(rows || this.rows, first),
        ...(sortField
          ? { sorting: this.sortingService.getParamsByOrder(sortField, sortOrder) }
          : {}),
        filtering: this._filtering || {},
      })
    }
  }

  private _onFilterFn(data: any) {
    this._filtering = data
    const { rows, sortField, sortOrder } = this.tableRef
    this.onLazyLoadFn({ rows, sortField, sortOrder, first: 0 })
  }

  private _toggleState(stateFn: () => void) {
    if (this.stateKey != null) {
      this.tableRef.stateKey = STATE_KEY_PREFIX + this.stateKey
      stateFn()
      this.tableRef.stateKey = null
    }
  }

  private _getState() {
    const storage = this.tableRef.getStorage()
    return JSON.parse(storage.getItem(this.tableRef.stateKey) as string)
  }

  private _isStateMatchingCols() {
    const sortJoin = (array: Array<string>) => array && [...array].sort().join(',')
    const columnOrder = (this._getState() || {}).columnOrder
    const columnFields = (this.columns || []).map(({ field }: { field: any }) => field)
    return (
      sortJoin(columnOrder) === sortJoin(columnFields) ||
      (columnOrder?.length && this.columnsFilter)
    )
  }

  private _restoreState() {
    this._toggleState(() => {
      // Bypassing PrimeNG's checks to allow restoring
      if (this._isStateMatchingCols()) {
        this.tableRef.restoreState()
        this.tableRef.restoreColumnOrder()
        this.onLazyLoadFn()
        this._restoreColumnsFilter()
        this.ref.detectChanges()
      } else {
        this.tableRef.clearState()
      }
    })
  }

  private _tooltipState = (value?: any) => {
    if (value) {
      window.localStorage.setItem(TOOLTIP_STATE_KEY, JSON.parse(value))
      return null
    } else {
      return window.localStorage.getItem(TOOLTIP_STATE_KEY)
    }
  }

  private _alterTooltipActivateFn() {
    const tooltipRef = this.tooltipButtonRef && this.tooltipButtonRef.tooltipRef
    if (!this._isTooltipActivateAltered && !this._tooltipState() && tooltipRef) {
      const fn = tooltipRef.activate
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const _this = this
      tooltipRef.activate = function () {
        _this._tooltipState(true)
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments as any)
      }
      this._isTooltipActivateAltered = true
    }
  }

  private _checkNextSelection(increment: number) {
    const { first, rows, sortField, sortOrder, totalRecords } = this.tableRef
    let firstOfPage: any
    if (increment < 0 && first > 0) {
      firstOfPage = first - rows
    } else if (increment > 0 && first + rows < totalRecords) {
      firstOfPage = first + rows
    }
    if (firstOfPage != null) {
      this._nextSelection = increment
      this.onLazyLoadFn({ rows, sortField, sortOrder, first: firstOfPage })
    }
  }

  private _detectChangesHeaderCheckbox() {
    this._headerCheckBox?.cd?.detectChanges()
  }

  private _reorderColumns(columnFields: string[], reorderMenu = true) {
    const columnsIndex = columnFields.reduce(
      (resp, field, i) => ({ ...resp, [field]: i }),
      {} as { [key: string]: number },
    )
    const columnsLength = this.columns.length + 1

    if (reorderMenu) {
      this.menuColumns.sort(
        (a, b) => (columnsIndex[a.id] ?? columnsLength) - (columnsIndex[b.id] ?? columnsLength),
      )
      this.menuColumns = this.menuColumns.map((item, i) => ({ ...item, disabled: i === 0 }))
    }

    this.selectedColumns = [...this.selectedColumns].sort(
      (a, b) => (columnsIndex[a.field] ?? columnsLength) - (columnsIndex[b.field] ?? columnsLength),
    )
  }

  ngOnInit() {
    this._initTriggers()
  }

  ngOnChanges(changes: SimpleChanges) {
    const tableDataChanges = (changes['tableData'] || <SimpleChanges>{}).currentValue
    if (tableDataChanges) {
      this.loading = !this.disableLoading && true
      if (this._prevTableDataChanges$) {
        this._prevTableDataChanges$.unsubscribe()
      }
      this._prevTableDataChanges$ = iif(
        () => isObservable(tableDataChanges),
        tableDataChanges,
        of(tableDataChanges),
      )
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.loading = false
          this._setTableData(data)
        })
    }
    if ((changes['columns'] || <SimpleChanges>{}).currentValue) {
      this._parseColumnSettings()
    }

    const filtersChanges = changes['filters'] || <SimpleChanges>{}
    if (filtersChanges.previousValue) {
      ;(<Subject<any>>filtersChanges.previousValue).unsubscribe()
    }
    if (filtersChanges.currentValue) {
      ;(<Subject<any>>filtersChanges.currentValue).subscribe((data) => this._onFilterFn(data))
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'custom-row':
          this.customRowTemplate = item.template
          break
        case 'collapsible-row':
          this.collapsibleRowTemplate = item.template
          break
        case 'context-menu':
          this.contextMenuTemplate = item.template
          break
        case 'full-custom-row':
          this.fullCustomRowTemplate = item.template
          break
      }
    })
  }

  ngAfterViewInit() {
    this._restoreState()
  }

  ngOnDestroy() {
    // This is intentional
  }

  showSaveTableTooltip(waitUntilReload = false) {
    if (this.tooltipButtonRef) {
      this.tooltipButtonRef.hideTooltip()
      this._shouldShowTooltip = waitUntilReload
      if (!this._tooltipState() && !this._shouldShowTooltip) {
        this.tooltipButtonRef.showTooltip(TOOLTIP_LIFE)
      }
    }
  }

  onPaging({ rows }: { rows: any }) {
    if (rows !== this.tableRows) {
      this.showSaveTableTooltip(true)
    }
  }

  clearSelection() {
    this.selection = this.selectionMode === SelectionMode.Single ? null : []
    this.selectionChange.emit(this.selection)
  }

  onRowSelect({ data }: TableRowSelectEvent, isSelected = true) {
    if (!isSelected) {
      this.selection =
        this.selection &&
        this.selection[this.selectionMode === SelectionMode.Single ? 'find' : 'filter'](
          (item: any) => item[this.indexColumn] !== data[this.indexColumn],
        )
    }
    this.selectionChange.emit(this.selection)
    if (this.selectionMode === SelectionMode.Multiple) {
      setTimeout(() => {
        if (this.headerCheckBox) {
          this.headerCheckBox.updateCheckedState()
          this._detectChangesHeaderCheckbox()
        }
      }, 0)
    }
  }

  toggleAllRowsSelection(event: MouseEvent) {
    if (this.disabled) {
      return
    }

    const tr = this.tableRef
    const check = (this.headerCheckBox.checked = !this.headerCheckBox.checked)
    const currentTableRowIds = (tr.filteredValue || tr.value || []).map(
      (row) => row[this.indexColumn],
    )
    const selectedRows = check ? tr.filteredValue || tr.value : []

    tr._selection = Array.from(
      new Set([
        ...(tr._selection || []).filter(
          (row: any) => !currentTableRowIds.includes(row[this.indexColumn]),
        ),
        ...selectedRows.filter((row) => !this._isDisableFn(row)),
      ]),
    )

    tr.preventSelectionSetterPropagation = true
    tr.updateSelectionKeys()
    tr.selectionChange.emit(tr._selection)
    tr.tableService.onSelectionChange()
    tr.onHeaderCheckboxToggle.emit({ originalEvent: event, checked: check })
    this.selection = tr._selection
    this.selectionChange.emit(this.selection)
    if (tr.isStateful()) {
      tr.saveState()
    }
    this._detectChangesHeaderCheckbox()
  }

  saveState() {
    this._toggleState(() => this.tableRef.saveState())
  }

  onLazyLoadFn(data?: any) {
    this._lazyLoadDebouncer.next(data)
  }

  exportCSV() {
    if (this.lazy) {
      this._startExportCSVLazy()
    } else {
      this.tableRef.exportCSV()
    }
  }

  reload() {
    this.onLazyLoadFn()
  }

  selectItem(increment: any) {
    if (this.selectionMode === SelectionMode.Single) {
      const selectionTemp = this.selection
      const itemIndex =
        this.items.findIndex(
          (item: any) => item[this.indexColumn] === this.selection[this.indexColumn],
        ) + increment
      if (itemIndex > -1 && itemIndex < this.items.length) {
        this.selection = this.items[itemIndex]
      } else if (!this._nextSelection) {
        this._checkNextSelection(increment)
      }
      if (this.selection !== selectionTemp) {
        this.selectionChange.emit(this.selection)
      }
    }
  }

  onMenuCheckedChange(filterColumns: string[]) {
    this._menuInteraction = true
    const columns = this.menuColumns.map(({ id }) => id).filter((id) => filterColumns.includes(id))
    this._setSelectedColumns(columns)
    this._reorderColumns(columns, false)
  }

  onColumnsReorder(reorderEvent: TableColumnReorderEvent) {
    this._reorderColumns(reorderEvent.columns.map((col) => col.field))
  }
}
